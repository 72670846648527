import React from "react";
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import '../../components/Breadcrumb.css'
import styled from "styled-components";
import media from "styled-media-query";

import Layout from "../../components/Layout";

import MainImage1 from "../../img/own-2.jpg";
import BgImage1 from "../../img/news-bg.png";

const AboutUsPage = ({ pageContext }) => {
  const { breadcrumb: { crumbs }} = pageContext;

  return (
    <Layout>
      {/*
      パンくず
      メインビジュアル
      タイトル
      2カラム紹介
      2カラムボタン
      詳細施設案内
      Googleマップ埋め込み
      共通の施設案内
      パンくず
      */}
      <Body>
      <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel="私たちのこと"  />
      <ImageBig src={MainImage1}></ImageBig>
      <MidashiBg>
        <Midashi>私たちのこと</Midashi>
      </MidashiBg>
      <Komidashi>ひとも場所もいきる、暮らしをつくる</Komidashi>
      <p>
      NAWATE PROJECTは 2012年、奉還町４丁目に鳥居のある大きな空き家をみつけ、<br />
      活用を考えることをきっかけに始まりました。<br />
      プロジェクトの名前は、活動拠点である奉還町４丁目の旧町名、畷元町の「畷(なわて)」の文字を引き継ぎ、<br />
      畷(田んぼのあぜ道)」のように、人と人、人と場所の関わりを生み出し、<br />
      それらを丁寧につないでいくことができたらという思いで名付けました。<br />
      <br />
      <br />
      まちに古くからある建物を受け継ぎ、<br />
      少しずつ自分たちの手で再生しては、今までになかった出来事や風景を生み出し、<br />
      昔ながらの商店街のなかに、あたらしいまちのあかりを灯してきました。<br />
      <br />
      <br />
      旅と日常が交わるこの場所で、<br />
      地域や私たちのプロジェクトに興味をもった人が共に日々を過ごし、暮らすための居場所をつくること。<br />
      さらに人々が自分なりの軸をもって町に関わることのできるような活動の足場としての役割を担うこと。<br />
      <br />
      <br />
      この２つの取り組みをきっかけに、それまで出会うことのなかった人々がこのまちで出会い、<br />
      人それぞれに、今までになかった価値観に触れ、アイデアや技術を交換したり、<br />
      個人の小さな活動が芽を出し育っていく、いきいきとした場を生み出す流れをつくります。<br />
      それこそが、人々が楽しく暮らすことのできるまちの始まりなのではないでしょうか。<br />
      <br />
      <br />
      私たちは、NAWATE PROJECTの活動を通じて、<br />
      個人や場所の持つ可能性や魅力を引き出していくことのできるようなまちをデザインしていきます。
      </p>

      </Body>
      <DetailDiv>
          <Nakamidashi>運営会社</Nakamidashi>
          <br />
          <br />
          <Nakakomidashi>NAWATE PROJECTは、合同会社さんさんごごが運営しています。</Nakakomidashi>
          <Intro2ColumnDiv>
              <div>
              <Column>
                <TitleInColumn>
                    <p><strong>名称</strong></p>
                </TitleInColumn>
                <p>合同会社さんさんごご</p>
              </Column>
              <Column>
                <TitleInColumn>
                    <p><strong>事業目的</strong></p>
                </TitleInColumn>
                <p>奉還町４丁目を拠点に、<br />人と人、人と場所をつなぎ、まちをデザインする。</p>
              </Column>
              <Column>
                <TitleInColumn>
                    <p><strong>事業内容</strong></p>
                </TitleInColumn>
                <p>- ゲストハウスとりいくぐるの運営<br />
                   - 奉還町４丁目ラウンジ・カドの運営<br />
                   - 複合施設NAWATEの運営 <br />
                   - シェアハウス気楽荘の管理および運営  <br />
                   - イベントの企画および運営- 商品開発およびデザイン<br />
                   - 物品の販売 <br />
                   - 文化事業のコーディネート、マネジメント<br />
                   - 地域振興事業のコーディネート、マネジメント</p>
              </Column>
              <Column>
                <TitleInColumn>
                    <p><strong>所在地</strong></p>
                </TitleInColumn>
                <p>700-0026 岡山市北区奉還町 4-7-15 (086-250-2629)</p>
              </Column>
              <Column>
                <TitleInColumn>
                    <p><strong>業務執行社員</strong></p>
                </TitleInColumn>
                <p>代表　石井信 (有限会社バルプラン)<br />
                    片岡八重子(ココロエ一級建築士事務所)<br />
                    成田海波<br />
                    従業員　石井智章<br />
                    他スタッフ2名</p>
              </Column>
              </div>
              <div>
              <Column>
                <TitleInColumn>
                    <p><strong>アーカイブ</strong></p>
                </TitleInColumn>
                <p>2012.9　NAWATE PROJECTスタート<br />
                   2013.4　なわてのお披露目見学会<br />
                   2013.7　NAWATE、とりいくぐるオープン<br />
                   2015.10　PROJECTの運営母体として合同会社さんさんごご設立 <br />
                   2016.7　シェアハウス気楽荘オープン <br />
                   2016.9　奉還町4 丁目ラウンジ・カドオープン <br />
                   2017.9　とりいくぐる別館オープン<br />
                   2018.6　nawateアーカイブ活動開始<br />
                   2020.4　NAWATEの畑づくり開始<br />
                   2020.5　とりいくぐるでのランチ・テイクアウト営業が始まる </p>
              </Column>
              </div>
          </Intro2ColumnDiv>
      </DetailDiv>
      <FooterDiv>
        <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel="私たちのこと"  />
      </FooterDiv>
    </Layout>
  );
}

export default AboutUsPage;

const Body = styled.div`
  margin: 0 5vw 3em 5vw;
  
`;

const MidashiBg = styled.div`
  width: 100%;
  background-image: url(${BgImage1});
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: 100%;
  text-align: center;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 8em;
    margin: 0 0 2em 0;
    padding: 2em 15vw 0 15vw;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 15em;
    margin: 0 0 5em 0;
    padding: 5em 15vw 0 15vw;
  `}
`;

const DetailDiv = styled.div`
  width: 100vw;
  background: #ffffff;
  border-radius: 10px;
  padding: 3vh 5vw 10vh 5vw;
`;

const Intro2ColumnDiv = styled.div`
  justify-content: space-between;
  width: 100%;
  margin: 10vh 0 0 0;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    display: flex;
  `}
`;

const FooterDiv = styled.div`
  margin: 3vh 5vw 3vh 5vw;
`;

const Column = styled.div`
  display: flex;
  padding: 2vh 1vw 2vh 1vw;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 90vw;
    border-top: 1px solid #666666;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    width: 42vw;
    border: 1px solid #666666;
  `}
`;

const TitleInColumn = styled.div`
  width: 10em;
`;

const ImageBig = styled.img`
  width: 100%;
  border-radius: 10px;
  margin: 0 0 3vh 0;
  object-fit: cover;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 30vh;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 80vh;
  `}
`;

const Midashi = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 20pt;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    font-size: 28pt;
  `}
`;

const Komidashi = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  margin: 0 0 5vh 0;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 14pt;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    font-size: 18pt;
  `}
`;

const Nakamidashi = styled.p`
font-family: 'Noto Sans JP', sans-serif;
font-weight: 700;
font-size: 18pt;
text-align: center;
`;

const Nakakomidashi = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  text-align: center;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 12pt;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    font-size: 14pt;
  `}
`;

